import './App.css';

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SearchPage from './components/SearchPage';
import ProductDetail from './components/ProductDetail';
import ApiCredentialsForm from './components/ApiCredentialsForm';
import { initDB, getProductsFromDB, addProductsToDB, clearProductsFromDB, getLastFetchTime, setLastFetchTime } from './utils/db';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [products, setProducts] = useState([]);
  const [apiKey, setApiKey] = useState(localStorage.getItem('apiKey') || '');
  const [apiSecret, setApiSecret] = useState(localStorage.getItem('apiSecret') || '');
  const [credentialsEntered, setCredentialsEntered] = useState(!!apiKey && !!apiSecret);

  const handleSaveCredentials = (key, secret) => {
    localStorage.setItem('apiKey', key);
    localStorage.setItem('apiSecret', secret);
    setApiKey(key);
    setApiSecret(secret);
    setCredentialsEntered(true);
  };

  const clearCredentials = async () => {
    // Clear localStorage items
    localStorage.clear();
  
    // Clear sessionStorage if used
    sessionStorage.clear();
  
    // Clear IndexedDB
    const db = await initDB();
    await clearProductsFromDB(db);
  
    // Reset state and trigger credentials re-entry
    setApiKey('');
    setApiSecret('');
    setCredentialsEntered(false);
  };

  useEffect(() => {
    const loadProducts = async () => {
      const db = await initDB();

      const lastFetchTime = await getLastFetchTime(db);
      const oneHourAgo = Date.now() - 60 * 60 * 1000;

      if (!lastFetchTime || lastFetchTime < oneHourAgo) {
        // If the data is stale or not present, fetch new data
        try {
          const response = await axios.get(
            `https://hub.unilite.co.uk/wp-json/uni/v1/webapp/master?key=${apiKey}&secret=${apiSecret}`
          );
          const fetchedProducts = response.data.data.products;

          // Clear the old products and add the new ones
          await clearProductsFromDB(db);
          await addProductsToDB(db, fetchedProducts);
          await setLastFetchTime(db, Date.now());

          setProducts(fetchedProducts);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            // If a 401 error occurs, clear credentials and reload the page
            clearCredentials();
            window.location.reload(); // Reload the page to show the credentials form
          } else {
            console.error('Error fetching products:', error);
          }
        }
      } else {
        // Use the products from the local database
        const storedProducts = await getProductsFromDB(db);
        setProducts(storedProducts);
      }
    };

    if (credentialsEntered) {
      loadProducts();
    }
  }, [credentialsEntered, apiKey, apiSecret]);

  return (
    <Router>
      <div className="App">
        {!credentialsEntered ? (
          <div className="centered-container">
            <ApiCredentialsForm onSave={handleSaveCredentials} />
          </div>
        ) : (
          <Routes>
            <Route path="/" element={<SearchPage products={products} />} />
            <Route path="/product/:id" element={<ProductDetail />} />
          </Routes>
        )}
      </div>
    </Router>
  );
}

export default App;
