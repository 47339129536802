import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  ListGroup,
  Button,
  Image,
} from "react-bootstrap";
import "./SearchPage.css";
import logo from "../assets/images/logo.svg";

function decodeHTMLEntities(text) {
  const textArea = document.createElement("textarea");
  textArea.innerHTML = text;
  return textArea.value;
}

function SearchPage({ products }) {
  const [query, setQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const navigate = useNavigate();

  const handleSearch = (e) => {
    setQuery(e.target.value);
    if (e.target.value.length > 2) {
      const results = products.filter((product) =>
        product.title.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredProducts(results);
    } else {
      setFilteredProducts([]);
    }
  };

  const handleSelectProduct = (product) => {
    navigate(`/product/${product.id}`);
  };

  const handleClearCredentials = () => {
    localStorage.removeItem("apiKey");
    localStorage.removeItem("apiSecret");
    navigate(0); // Reload the page to trigger the credentials form
  };

  return (
    <Container className="search-page">
      <Image src={logo} alt="Unilite Logo" className="search_logo" fluid />
      <Row className="justify-content-center align-items-center min-vh-100">
        <Col className="sec_color" md={8} lg={6}>
          <h1 className="text-center mb-4">Product Search</h1>
          <InputGroup>
            <Form.Control
              type="text"
              value={query}
              onChange={handleSearch}
              placeholder="Search for a product..."
              className="search-input"
            />
          </InputGroup>
          {filteredProducts.length > 0 && (
            <ListGroup className="search-results">
              {filteredProducts.map((product) => (
                <ListGroup.Item
                  key={product.id}
                  onClick={() => handleSelectProduct(product)}
                  className="search-result-item"
                >
                  {decodeHTMLEntities(product.title)}
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
          <div className="clear_api">
            <Button variant="danger" onClick={handleClearCredentials}>
              Clear API Credentials
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default SearchPage;
