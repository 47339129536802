import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { initDB, getProductsFromDB } from "../utils/db";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Spinner,
  Alert,
} from "react-bootstrap";
import "./ProductDetail.css";

function decodeHTMLEntities(text) {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  return textArea.value;
}

function ProductDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true); // Track loading state
  const [error, setError] = useState(null); // Track error state

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const db = await initDB();
        const products = await getProductsFromDB(db);
        const selectedProduct = products.find(
          (item) => item.id === parseInt(id)
        );
        if (selectedProduct) {
          const filteredProduct = {
            ...selectedProduct,
            description: {
              short_title: Array.isArray(selectedProduct.description?.short_title)
                ? selectedProduct.description.short_title.filter(
                    (desc) => desc.language === "en"
                  )
                : selectedProduct.description?.short_title ?? [],
              short_description: Array.isArray(selectedProduct.description?.short_description)
                ? selectedProduct.description.short_description.filter(
                    (desc) => desc.language === "en"
                  )
                : selectedProduct.description?.short_description ?? [],
              full_description: Array.isArray(selectedProduct.description?.full_description)
                ? selectedProduct.description.full_description.filter(
                    (desc) => desc.language === "en"
                  )
                : selectedProduct.description?.full_description ?? [],
              features: Array.isArray(selectedProduct.description?.features)
                ? selectedProduct.description.features.filter(
                    (feature) => feature.language === "en"
                  )
                : selectedProduct.description?.features ?? [],
            },
            specs: {
              ...selectedProduct.specs,
              materials: Array.isArray(selectedProduct.specs?.materials)
                ? selectedProduct.specs.materials.filter(
                    (material) => material.language === "en"
                  )
                : selectedProduct.specs?.materials ?? [],
            },
            // ... rest of the filteredProduct object ...
          };
          setProduct(filteredProduct);
        } else {
          setError("Invalid product data received.");
          return;
        }
      } catch (err) {
        console.error("Error details:", err);
        if (err.name === "DatabaseError") {
          setError("Database error. Please try again later.");
        } else if (err.name === "NetworkError") {
          setError("Network error. Please check your connection and try again.");
        } else {
          setError("Failed to load product data. Please try again later.");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);

  if (loading)
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  if (error) return <Alert variant="danger">{error}</Alert>;

  return (
    <Container className="product-detail">
      <Button
        variant="secondary"
        onClick={() => navigate("/")}
        className="top_btn"
      >
        Return to Search
      </Button>
      <Row>
      <Col md={6} lg={4} > <div className="sec_color">
          {product.image && (
            <Image
              src={product.image}
              alt={product.title}
              className="product-image"
              fluid
            />
          )}
        </div> </Col>
        <Col md={6} lg={4} > <div className="sec_color">
          <h1 className="product-title">{decodeHTMLEntities(product.title)}</h1>

          {product.description?.short_description[0]?.description && (
            <p className="product-short-description">
              {product.description.short_title[0].description}
            </p>
          )}
          {product.description?.full_description[0]?.description && (
            <p className="product-full-description">
              {product.description.full_description[0].description}
            </p>
          )}
          </div> </Col>
      <Col md={6} lg={4} > <div className="sec_color">
          {product.description.features.length > 0 && (
            <div className="product-features">
              <h5>Key Features</h5>
              <ul>
                {product.description.features.map((feature, index) => (
                  <li key={index}>{feature.feature}</li>
                ))}
              </ul>
            </div>
          )}
</div> </Col>

<Col md={6} lg={4} > <div className="sec_color">
  <div className="specifications-section">
  <h5>Specifications</h5>

    {product.identity?.gtin && (
      <p><strong>GTIN:</strong> {product.identity.gtin}</p>
    )}
    {product.identity?.mpn && (
      <p><strong>MPN:</strong> {product.identity.mpn}</p>
    )}
    {product.identity?.country_of_origin && (
      <p><strong>Country of Origin:</strong> {product.identity.country_of_origin}</p>
    )}
    {product.identity?.commodity_code && (
      <p><strong>Commodity Code:</strong> {product.identity.commodity_code}</p>
    )}
    {product.identity?.google_category && (
      <p><strong>Google Category:</strong> {product.identity.google_category}</p>
    )}
    {product.recommended_pricing?.[0]?.price && (
      <p><strong>Price (GBP):</strong> £{product.recommended_pricing[0].price}</p>
    )}
    {product.specs?.colour && (
      <p><strong>Color:</strong> {product.specs.colour}</p>
    )}
    {product.specs?.internal_volume && (
      <p><strong>Internal Volume:</strong> {product.specs.internal_volume}</p>
    )}
    {product.specs?.max_payload && (
      <p><strong>Max Payload:</strong> {product.specs.max_payload}</p>
    )}
    {product.specs?.pockets && (
      <p><strong>Pockets:</strong> {product.specs.pockets}</p>
    )}
    {product.specs?.materials?.length > 0 && (
      <p><strong>Materials:</strong> {product.specs.materials.map((material, index) => (
        <span key={index}>
          {material.material}{index < product.specs.materials.length - 1 && ', '}
        </span>
      ))}</p>
    )}
    {product.specs?.ip && product.specs.ip !== "0" && (
      <p><strong>IP Rating:</strong> {product.specs.ip}</p>
    )}
    {product.specs?.ik && product.specs.ik !== "0" && (
      <p><strong>IK Rating:</strong> {product.specs.ik}</p>
    )}
    {product.specs?.beam_range && product.specs.beam_range !== "0" && (
      <p><strong>Beam Range:</strong> {product.specs.beam_range}</p>
    )}
    {product.specs?.led && product.specs.led !== "0" && (
      <p><strong>LED:</strong> {product.specs.led}</p>
    )}
    {product.specs?.run_time?.min && product.specs.run_time.min !== "0" && (
      <p><strong>Run Time:</strong> {product.specs.run_time.min} - {product.specs.run_time.max} hours</p>
    )}
    {product.specs?.operating_temperature?.min && product.specs.operating_temperature.min !== "0" && (
      <p><strong>Operating Temperature:</strong> {product.specs.operating_temperature.min}°C to {product.specs.operating_temperature.max}°C</p>
    )}
    {product.power?.power_type && (
      <p><strong>Power Type:</strong> {product.power.power_type}</p>
    )}
    {product.power?.input_power?.voltage && (
      <p><strong>Input Power:</strong> {product.power.input_power.voltage}V / {product.power.input_power.Amps}A</p>
    )}
    {product.power?.output_power?.voltage && (
      <p><strong>Output Power:</strong> {product.power.output_power.voltage}V / {product.power.output_power.Amps}A</p>
    )}
    {product.power?.battery?.type && product.power.battery.type !== "None" && (
      <p><strong>Battery:</strong> {product.power.battery.type}
        {product.power.battery.type !== "None" && ` (${product.power.battery.voltage}V, ${product.power.battery.capacity}mAh)`}</p>
    )}
  </div>
</div> </Col>

<Col md={6} lg={4} > <div className="sec_color">
<h5>Measurements</h5>
  {product.measurements?.product && (
    <div className="measurement-section mb-4">
      <h4 className="mb-3">Product</h4>
      {product.measurements.product.length &&
        product.measurements.product.width &&
        product.measurements.product.height && (
          <p>
            {product.measurements.product.length}mm x{" "}
            {product.measurements.product.width}mm x{" "}
            {product.measurements.product.height}mm
          </p>
        )}
      {product.measurements.product.weight && (
        <p>Weight: {product.measurements.product.weight}g</p>
      )}
    </div>
  )}

  {product.measurements?.packaging && (
    <div className="measurement-section mb-4">
      <h4 className="mb-3">Packaging</h4>
      {product.measurements.packaging.length &&
        product.measurements.packaging.width &&
        product.measurements.packaging.height && (
          <p>
            {product.measurements.packaging.length}mm x{" "}
            {product.measurements.packaging.width}mm x{" "}
            {product.measurements.packaging.height}mm
          </p>
        )}
      {product.measurements.packaging.weight && (
        <p>Weight: {product.measurements.packaging.weight}g</p>
      )}
    </div>
  )}

  {product.measurements?.secondary_packaging &&
    (product.measurements.secondary_packaging.length !== "0" ||
      product.measurements.secondary_packaging.width !== "0" ||
      product.measurements.secondary_packaging.height !== "0" ||
      product.measurements.secondary_packaging.weight !== "0" ||
      product.measurements.secondary_packaging.qty !== "0") && (
      <div className="measurement-section mb-4">
        <h4 className="mb-3">Secondary Packaging</h4>
        {product.measurements.secondary_packaging.length &&
          product.measurements.secondary_packaging.width &&
          product.measurements.secondary_packaging.height && (
            <p>
              {product.measurements.secondary_packaging.length}mm x{" "}
              {product.measurements.secondary_packaging.width}mm x{" "}
              {product.measurements.secondary_packaging.height}mm
            </p>
          )}
        {product.measurements.secondary_packaging.weight && (
          <p>Weight: {product.measurements.secondary_packaging.weight}g</p>
        )}
        {product.measurements.secondary_packaging.qty && (
          <p>Quantity: {product.measurements.secondary_packaging.qty}</p>
        )}
      </div>
    )}

  {product.measurements?.tertiary_packaging &&
    (product.measurements.tertiary_packaging.length !== "0" ||
      product.measurements.tertiary_packaging.width !== "0" ||
      product.measurements.tertiary_packaging.height !== "0" ||
      product.measurements.tertiary_packaging.weight !== "0" ||
      product.measurements.tertiary_packaging.carton_qty !== "0") && (
      <div className="measurement-section mb-4">
        <h4 className="mb-3">Tertiary Packaging</h4>
        {product.measurements.tertiary_packaging.length &&
          product.measurements.tertiary_packaging.width &&
          product.measurements.tertiary_packaging.height && (
            <p>
              {product.measurements.tertiary_packaging.length}mm x{" "}
              {product.measurements.tertiary_packaging.width}mm x{" "}
              {product.measurements.tertiary_packaging.height}mm
            </p>
          )}
        {product.measurements.tertiary_packaging.weight && (
          <p>Weight: {product.measurements.tertiary_packaging.weight}g</p>
        )}
        {product.measurements.tertiary_packaging.carton_qty && (
          <p>Carton Quantity: {product.measurements.tertiary_packaging.carton_qty}</p>
        )}
      </div>
    )}
</div> </Col>
          <Col md={6} lg={4} > <div className="sec_color">
          <h5>Downloads</h5>
          {(product.document?.manual ||
            product.document?.data_sheet ||
            product.document?.declaration) && (
            <div className="product-doc-links">
              {product.document.manual && (
                  <a
                    href={product.document.manual}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="product-doc-link"
                  >
                    View Manual
                  </a>
              )}
              {product.document.data_sheet && (
                  <a
                    href={product.document.data_sheet}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="product-doc-link"
                  >
                    View Data Sheet
                  </a>
              )}
              {product.document.declaration && (
                  <a
                    href={product.document.declaration}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="product-doc-link"
                  >
                    View Declaration of Conformity
                  </a>
              )}
            </div>
          )}
        </div> </Col>
      </Row>
    </Container>
  );
}

export default ProductDetail;
