import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";
import "./ApiCredentialsForm.css";
import logo from "../assets/images/logo.svg";

function ApiCredentialsForm({ onSave }) {
  const [apiKey, setApiKey] = useState("");
  const [apiSecret, setApiSecret] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (apiKey && apiSecret) {
      onSave(apiKey, apiSecret);
    }
  };

  return (
    <Container className="api-credentials-form">
      <Image src={logo} alt="Unilite Logo" className="search_logo" fluid />
      <Row className="justify-content-center align-items-center min-vh-100">
        <Col md={6} lg={4} > <div className="sec_color">
          <h2 className="text-center mb-4">Enter API Credentials</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="apiKey">
              <Form.Control
                className="type-input"
                type="text"
                placeholder="Enter your API key"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="apiSecret">
              <Form.Control
                className="type-input"
                type="password"
                placeholder="Enter your API secret"
                value={apiSecret}
                onChange={(e) => setApiSecret(e.target.value)}
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </div> </Col>
      </Row>
    </Container>
  );
}

export default ApiCredentialsForm;
