// src/utils/db.js
import { openDB } from 'idb';

const DB_NAME = 'ProductDB';
const PRODUCT_STORE = 'products';
const META_STORE = 'meta';
const LAST_FETCH_KEY = 'lastFetch';

export const initDB = async () => {
  const db = await openDB(DB_NAME, 2, {  // Incremented version to trigger upgrade
    upgrade(db) {
      // Create object store for products if it doesn't exist
      if (!db.objectStoreNames.contains(PRODUCT_STORE)) {
        db.createObjectStore(PRODUCT_STORE, { keyPath: 'id' });
      }
      // Create object store for metadata if it doesn't exist
      if (!db.objectStoreNames.contains(META_STORE)) {
        db.createObjectStore(META_STORE);
      }
    },
  });
  return db;
};

export const getProductsFromDB = async (db) => {
  return await db.getAll(PRODUCT_STORE);
};

export const addProductsToDB = async (db, products) => {
  const tx = db.transaction(PRODUCT_STORE, 'readwrite');
  const store = tx.objectStore(PRODUCT_STORE);
  for (const product of products) {
    await store.put(product);
  }
  await tx.done;
};

export const clearProductsFromDB = async (db) => {
  const tx = db.transaction(PRODUCT_STORE, 'readwrite');
  await tx.objectStore(PRODUCT_STORE).clear();
  await tx.done;
};

export const getLastFetchTime = async (db) => {
  return await db.get(META_STORE, LAST_FETCH_KEY);
};

export const setLastFetchTime = async (db, timestamp) => {
  const tx = db.transaction(META_STORE, 'readwrite');
  await tx.objectStore(META_STORE).put(timestamp, LAST_FETCH_KEY);
  await tx.done;
};
